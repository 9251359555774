<template>
  <div>
    <el-carousel :height="carousel_height" v-if="lang != 'en'">
      <el-carousel-item v-for="(url, index) in banners" :key="index">
        <el-image style="width:100%;height: 100%;" :src="$host + $t(url, 'zh')"></el-image>
      </el-carousel-item>
    </el-carousel>

    <div class="list">
      <div class="index-item">
        <el-card class="no-shadow no-border">
          <div class="padding center">
            <h2>{{ $t('PTE机经练习') }}</h2>

            <div class="mt margin-t">{{ $t('技巧与机经结合练习，快速提升答题效率和正确率') }}</div>
          </div>

          <el-row class="margin-t margin-x" :gutter="10">
            <el-col :span="6">
              <el-card class="relative hover">
                <div class="flex flex-between pointer margin-x"
                  @click="to('/main/practise#' + $store.state.sorts[0].title)">
                  <div>
                    <h4>{{ $t('口语') }}</h4>
                    <el-link class="margin-t st">{{ $t('开始练习') }}<i class="el-icon-right"></i></el-link>
                  </div>
                  <el-image fit="cover" class="icon-img" src="/web/image/home/speak.png"></el-image>
                </div>
                <el-image class="icon-ai" src="/web/image/home/ai.png"></el-image>
              </el-card>
            </el-col>
            <el-col :span="6">
              <el-card class="relative hover">
                <div class="flex flex-between pointer margin-x"
                  @click="to('/main/practise#' + $store.state.sorts[1].title)">
                  <div>
                    <h4>{{ $t('写作') }}</h4>
                    <el-link class="margin-t st">{{ $t('开始练习') }}<i class="el-icon-right"></i></el-link>
                  </div>
                  <el-image fit="cover" class="icon-img" src="/web/image/home/write.png"></el-image>
                </div>
                <el-image class="icon-ai" src="/web/image/home/ai.png"></el-image>
              </el-card>
            </el-col>
            <el-col :span="6">
              <el-card class="hover">
                <div class="flex flex-between pointer margin-x"
                  @click="to('/main/practise#' + $store.state.sorts[2].title)">
                  <div>
                    <h4>{{ $t('阅读') }}</h4>
                    <el-link class="margin-t st">{{ $t('开始练习') }}<i class="el-icon-right"></i></el-link>
                  </div>
                  <el-image fit="cover" class="icon-img" src="/web/image/home/read.png"></el-image>
                </div>

              </el-card>
            </el-col>
            <el-col :span="6">
              <el-card class="relative hover">
                <div class="flex flex-between pointer margin-x"
                  @click="to('/main/practise#' + $store.state.sorts[3].title)">
                  <div>
                    <h4>{{ $t('听力') }}</h4>
                    <el-link class="margin-t st">{{ $t('开始练习') }}<i class="el-icon-right"></i></el-link>
                  </div>
                  <el-image fit="cover" class="icon-img" src="/web/image/home/listen.png"></el-image>
                </div>
                <el-image class="icon-ai" src="/web/image/home/ai.png"></el-image>
              </el-card>
            </el-col>
          </el-row>
        </el-card>
      </div>







      <div class="index-item">
        <el-card class="no-shadow no-border">
          <h2 class="padding center">{{ $t('了解PTE') }}</h2>

          <div class="margin-x margin-lt flex" style="column-gap: 40px;">
            <el-card class="flex1">
              <div class="flex flex-v-center">
                <el-image src="/web/image/home/01.png"></el-image>
                <el-card class="no-shadow no-border margin-x">
                  <h4 slot="header" class="ellipsis">{{ $t('PTE认可学校和机构') }}</h4>
                  <div class="margin-t mt">
                    {{ $t('PTE学术英语考试已被全球54个国家、数千所综合大学政府机构及其他教育院校认可。适用于留学，移民，海外就业等多种签证申请') }}
                  </div>

                  <div class="margin-t right">
                    <el-link target="blank"
                      :href="`https://www.pearsonpte.com${$store.state.lang != 'en' ? '.cn' : ''}/who-accepts-pte`">{{
                        $t('了解更多') }}<i class="el-icon-arrow-right"></i></el-link>
                  </div>
                </el-card>
              </div>
            </el-card>


            <el-card class="flex1">
              <div class="flex flex-v-center">
                <el-image src="/web/image/home/02.png" class="margin-x"></el-image>
                <el-card class="no-shadow no-border margin-x">
                  <h4 slot="header">{{ $t('PTE报名流程') }}</h4>
                  <div class="margin-t mt">
                    {{ $t('PTE学术英语考试全年360天均有考试安排，最大程度满足考生的报考需求，考试最快可提前24小时报名，可报考14天内的考试。') }}
                  </div>

                  <div class="margin-t right">
                    <el-link target="blank"
                      :href="$store.state.lang == 'en' ? 'https://www.pearsonpte.com/anz?utm_source=googleppc&utm_medium=cpc&utm_campaign=PTE_LightsOn_ANZ_PPC_Buy&utm_term=Search&utm_content=Brand&gad_source=1&gclid=Cj0KCQiAwtu9BhC8ARIsAI9JHanjYQPTDqw3BwkcmtuVFhIUDNTfwuLeXLNL-LpNMS1wUpegwjzR1XUaAilXEALw_wcB&gclsrc=aw.ds' : 'https://app.jingsocial.com/artview/index?openid=o6Z381CUQGo9HZJkOhhsnWoqBHro&wid=225&ord=0&rid=392275&whoshareopenid=o6Z381JrSCCCcEoJd03PYPigAH-k&whocurrentshareopenid=o6Z381CUQGo9HZJkOhhsnWoqBHro&kol=&relation_type=0&relation_id=&relation_extra_id=&jdt=Q%253D%253DeyJyZWxhdGlvbl90eXBlIjozLCJzZW5kX2NoYW5uZWxfdHlwZSI6InN5c3RlbV9tZW51X3NlbmQiLCJzZW5kX2NoYW5uZWxfb2JqX2lkIjoiODM5Iiwic2VuZF9jaGFubmVsX2V4dHJhX29ial9pZCI6IjMwMTE3Iiwic2VuZF9jaGFubmVsX2V4dHJhX29ial9pZDIiOiJcdTgwMDNcdThiZDVcdTYyYTVcdTU0MGQif'">{{
                        $t('了解更多') }}<i class="el-icon-arrow-right"></i></el-link>
                  </div>
                </el-card>
              </div>
            </el-card>
          </div>

          <el-row class="margin-x margin-lt" :gutter="160">
            <el-col :span="24" class="center">
              <el-image :src="`/web/image/home/5-2${lang}.jpeg?v=1`" style="width:100%"></el-image>
            </el-col>
          </el-row>
        </el-card>
      </div>



      <div class="index-item">
        <el-card class="no-shadow no-border">
          <!-- <h2 class="padding center">PTE vs IELTS</h2> -->
          <el-row class="margin-x" :gutter="40">
            <el-col :span="24">
              <el-image style="width:100%" :src="`/web/image/home/6-1${lang}.jpeg`">
              </el-image>
            </el-col>
          </el-row>
        </el-card>
      </div>


      <div class="index-item course">
        <el-card class="no-shadow no-border">
          <h2 class="padding center">{{ $t('PTE课程指南') }}</h2>

          <div class="mt center margin-x">{{ $t('海澳英语所有课程均由业内教学经验丰富的顶级名师进行教授，涵盖完整PTE内容体系。') }}

          </div>

          <div class="mt center"> {{ $t('线上线下课程同步进行，线上课程有视频回放，方便同学进行复习。') }}</div>
          <div class="course-list">

            <div class="course-item">
              <el-card>
                <div style="background: url(/web/image/home/4-1.png);" class="padding top-radius bg-100 color-white">
                  <h3 class="ellipsis">{{ $t('基础技巧精讲班') }}</h3>
                  <div>
                    <div class="margin-t st color-white">
                      <div class="dot"></div>{{ $t('针对从未接触过pte考试的学员，从零开始了解PTE听说读写四项题型技巧。') }}
                    </div>
                    <div class="st color-white">
                      <div class="dot"></div>{{ $t('独家编制配套教材，涵盖PTE考试的评分体系，题型精讲，答题要点，套路模版，难点剖析。') }}
                    </div>
                    <div class="st color-white">
                      <div class="dot"></div>{{ $t('直播后有回放，可反复学习') }}
                    </div>
                  </div>
                </div>
                <div class="flex flex-between margin-t padding">
                  <el-button class="c1" size="small" @click="dialogVisible = true">{{ $t('咨询课程') }}</el-button>
                  <el-button class="" @click="to('/media?tag=course&id=3')" size="small" plain>{{ $t('查看详情')
                  }}</el-button>
                </div>

              </el-card>
            </div>

            <div class="course-item">
              <el-card>
                <div style="background: url(/web/image/home/4-4.png);" class="padding top-radius bg-100 color-white">
                  <h3 class="ellipsis">{{ $t('全项机经刷题班') }}</h3>
                  <div>
                    <div class="margin-t st color-white">
                      <div class="dot"></div>{{ $t('作为技巧课的后置课程，练习课为高密度带练刷高频预测，快速提高做题能力') }}
                    </div>
                    <div class="st color-white">
                      <div class="dot"></div>{{ $t('带练中背熟真题，建立做题思维，打牢基础的同时，熟悉机经题目') }}
                    </div>
                    <div class="st color-white">
                      <div class="dot"></div>{{ $t('考什么就刷什么，甄选机经原题，提高复习效率，考前冲刺必备') }}
                    </div>
                  </div>
                </div>

                <div class="flex flex-between margin-t padding">
                  <el-button class="c4" size="small" @click="dialogVisible = true">{{ $t('咨询课程') }}</el-button>
                  <el-button @click="to('/media?tag=course&id=4')" size="small" plain>{{ $t('查看详情') }}</el-button>
                </div>
              </el-card>
            </div>


            <div class="course-item">
              <el-card>
                <div style="background: url(/web/image/home/4-3.png);" class="padding top-radius bg-100 color-white">
                  <h3 class="ellipsis">{{ $t('技巧刷题保过班') }}</h3>
                  <div>
                    <div class="margin-t st color-white">
                      <div class="dot"></div>{{ $t('包含20小时基础技巧课+40小时全项机经刷题课。') }}
                    </div>
                    <div class="st color-white">
                      <div class="dot"></div>{{ $t('保过班的所有课程同学可以无限次循环听课，直至考出目标分数。') }}
                    </div>
                    <div class="st color-white">
                      <div class="dot"></div>{{ $t('建立专属学习群进行课后辅导以及专业批改。') }}
                    </div>
                  </div>
                </div>

                <div class="flex flex-between margin-t padding">
                  <el-button class="c3" size="small" @click="dialogVisible = true">{{ $t('咨询课程') }}</el-button>
                  <el-button @click="to('/media?tag=course&id=1')" size="small" plain>{{ $t('查看详情') }}</el-button>
                </div>
              </el-card>
            </div>

            <div class="course-item">
              <el-card>
                <div style="background: url(/web/image/home/4-2.png);" class="padding top-radius bg-100 color-white">
                  <h3 class="ellipsis">{{ $t('1v1 VIP私人订制课程') }}</h3>

                  <div>
                    <div class="margin-t st color-white">
                      <div class="dot"></div>{{ $t('顶级名师一对一全程亲自辅导指点') }}
                    </div>
                    <div class="st color-white">
                      <div class="dot"></div>{{ $t('涵盖完整PTE知识内容体系') }}
                    </div>
                    <div class="st color-white">
                      <div class="dot"></div>{{ $t('薄弱环节个个击破') }}
                    </div>
                    <div class="st color-white">
                      <div class="dot"></div>{{ $t('考前冲刺模考 名师坐镇 发现问题 解决问题') }}
                    </div>
                    <div class="st color-white">
                      <div class="dot"></div>{{ $t('专项训练查缺补漏') }}
                    </div>
                  </div>
                </div>

                <div class="flex flex-between margin-t padding">
                  <el-button class="c2" size="small" @click="dialogVisible = true">{{ $t('咨询课程') }}</el-button>
                  <el-button @click="to('/media?tag=course&id=2')" size="small" plain>{{ $t('查看详情') }}</el-button>
                </div>
              </el-card>
            </div>
          </div>
        </el-card>
      </div>




      <div class="index-item" v-if="teachers.length <= 4">
        <el-card class="no-shadow no-border">
          <h2 class="padding center">{{ $t('名师介绍') }}</h2>
          <el-row class="margin-lt" :gutter="50" v-for="row in Math.ceil(teachers.length / 2)" :key="row">
            <el-col v-for="(item, index1) in teachers.slice((row - 1) * 2, row * 2)" :key="index1" :span="12">
              <div class="flex flex-v-center">
                <img style="height:100px;width: 100px;border-radius: 50%;" :src="$host + item.avatar" />
                <div class="margin-l">
                  <h4 class="margin-lt">{{ item.name }}</h4>
                  <div class="margin-t mt">{{ $t(item.title) }}</div>
                </div>
              </div>


              <div class="margin-t mt">
                <div v-for="(row, index) in $t1(item, 'content').split('\n')" :key="index">
                  <div>{{ row }}</div>
                </div>
              </div>
            </el-col>
          </el-row>
        </el-card>
      </div>
      <div class="index-item" v-else>
        <el-card class="no-shadow no-border">
          <h2 class="padding center">{{ $t('名师介绍') }}</h2>
          <div class="flex flex-v-center flex-h-center margin-t" v-if="teachers.length > 0">
            <div>
              <img style="height: 300px;width: auto;" :src="$host + teachers[teacherIndex].avatar" />
            </div>
            <div style="width: 500px;margin-left: 40px;">
              <div class="bold">
                {{ teachers[teacherIndex].name }}
              </div>
              <div class="margin-t mt">
                {{ $t1(teachers[teacherIndex], 'title') }}
              </div>

              <div class="margin-t mt">
                <div v-for="(row, index) in $t1(teachers[teacherIndex], 'content').split('\n')" :key="index">
                  <div>{{ row }}</div>
                </div>
              </div>
            </div>
          </div>

          <div class="flex flex-h-center margin-t flex-v-center flex-wrap">
            <div :class="{ 'current': index == teacherIndex }" @click="teacherIndex = index"
              v-for="(item, index) in teachers" :key="index" class="margin-x margin-t">
              <img class="teacher-avatar" :src="$host + item.avatar" />
              <div class="center st1">{{ item.name }}</div>
            </div>
          </div>
        </el-card>
      </div>


      <div class="index-item">
        <el-card class="no-shadow no-border">
          <h2 class="padding center">{{ $t('海澳教学体系') }}</h2>
          <div class="margin-lt flex flex-h-center">

            <el-card class="center card">
              <el-image src="/web/image/home/10-1.png"></el-image>
              <h3 class="margin-t">{{ $t('名师教学') }}</h3>
              <div class="margin-t st mc">{{ $t('由教学经验丰富的James老师进行课堂教学') }}</div>
            </el-card>


            <el-card class="center card" style="margin:0 40px;">
              <el-image src="/web/image/home/10-2.png"></el-image>
              <h3 class="margin-t">{{ $t('学习跟踪') }}</h3>
              <div class="margin-t st mc">{{ $t('课后跟踪同学学习进度及学习情况，及时调整优化学习方案') }}</div>
            </el-card>

            <el-card class="center card">
              <el-image src="/web/image/home/10-3.png"></el-image>
              <h3 class="margin-t">{{ $t('最全题库') }}</h3>
              <div class="margin-t st mc">{{ $t('题库实时更新，便于同学学习和练习最新的真题') }}</div>
            </el-card>
          </div>
        </el-card>
      </div>

      <div class="index-item">
        <el-card class="no-shadow no-border">
          <h2 class="padding center">{{ $t('干货文章') }} <el-link @click="to('/article')" class="mt" style="float:right">{{
            $t('查看更多') }}<i class="el-icon-arrow-right"></i></el-link></h2>

          <el-carousel arrow="always" :autoplay="false" @change="artChange" :initial-index="1" :loop="false"
            class="flex1 margin-t" :interval="4000" type="card" height="380px">
            <el-carousel-item
              v-for="(article, index) in $store.state.articles.filter((val) => { return val.is_index == 1 })"
              :key="index">
              <el-card class="center"
                style="height:100%;background-size:100% 100%;background-repeat: no-repeat;background-position: center;width: 380px;margin:0 auto;"
                :style="{ 'background-image': 'url(' + ($host + $url(article, 'cover')) + ')' }">
                <div @click="to('/media?tag=article&id=' + article.id)">
                  <div style="height:312px"></div>
                  <div class="bg0">
                    <h3 class="color-white">{{ $t1(article, 'title') }}</h3>
                    <!-- <div class="margin-t st">{{ article.description }}
                    </div> -->
                  </div>
                </div>
              </el-card>
            </el-carousel-item>
          </el-carousel>

        </el-card>
      </div>



      <el-dialog :visible.sync="dialogVisible" width="480px" center>
        <h3 slot="title">{{ $t('扫码咨询课程') }}</h3>
        <el-image style="width:100%;height:auto;" :src="$host + setting.kf_qrcode"></el-image>
      </el-dialog>
    </div>
  </div>
</template>

<script>
export default {
  data: function () {
    return {
      carousel_height: "480px",
      dialogVisible: false,
      windowWidth: null,
      articleIndex: 1,
      teacherIndex: 0,
    }
  },

  computed: {
    banners: function () {
      if (this.lang == 'tw') {
        return this.setting.banners_tw
      }
      return this.setting.banners
    },

    teachers: function () {
      return this.$store.state.teachers
    },
    lang: function () {
      return this.$store.state.lang
    },
    setting: function () {
      return this.$store.state.setting
    }
  },

  watch: {
    windowWidth(val) {
      this.carousel_height = val * 1000 / 3840 + 'px'
    }

  },

  mounted() {
    this.windowWidth = document.documentElement.clientWidth;
    this.carousel_height = this.windowWidth * 1000 / 3840 + 'px';
    window.onresize = () => {
      return (() => {
        this.windowWidth = document.documentElement.clientWidth; // 宽
      })()
    };
  },

  activated() {
    this.$store.commit('setMode', 'PTE')
    this.$bus.$emit("scrollTop");
  },




  methods: {
    to(url) {
      this.$router.push(url)
    },

    artChange(index) {
      this.articleIndex = index;
    }
  },
};
</script>

<style scoped>
.list {
  width: 1060px;
  margin: 0 auto;
}


.course-list {
  display: flex;
  flex-wrap: wrap;
  /* 允许换行 */
  align-items: stretch;
}


.course-item {
  flex: 0 1 calc(33.33% - 40px);
  /* 每个元素占33.33%宽度，减去间距 */
  margin: 20px;
  /* 间距 */
  box-sizing: border-box;
  /* 确保宽度包含padding和border */
}

.course-item>>>.el-card {
  height: 100%;
}

.course-item>>>.el-card__body {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.course-item .bg-100 {
  flex: 1;
}

.index-item {
  margin-top: 40px;
}


.card {
  width: 300px;
}

.hover:hover {
  cursor: pointer;
  border: 1px solid #f2f2f2;
  box-shadow: #ffc36f 0px 0px 6px 4px;
}


.index-item>>>.el-tabs__item.is-active {
  background-color: #1ecec0;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  color: #fff;
}

.index-item>>>.el-tabs__item.is-active:hover {
  color: #fff;
}

.index-item>>>.el-tabs__item:hover {
  color: #1ecec0;
}

.index-item>>>.el-tabs__nav-wrap {
  padding-right: 40px;
}

.index-item>>>.el-tabs__item {
  margin-top: 30px;
  margin-bottom: 30px;
}

.index-item>>>.el-tabs__active-bar {
  display: none;
}

.icon-ai {
  position: absolute;
  top: 0;
  right: 0;
}

.course>>>.el-card {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.course>>>.el-card__body {
  padding: 0 !important;

}

.icon-img {
  height: 50px;
  width: auto;
}

.bg0 {
  margin: 0 -20px;
  padding: 10px 0;
  background-color: rgba(0, 0, 0, 0.5);
}



.c1 {
  background-color: #85afca;
  color: #fff;
}

.c1:hover {
  background-color: #a2c9e2;
}

.c2 {
  background-color: #bb5662;
  color: #fff;
}

.c2:hover {
  background-color: #e7828e;
}

.c3 {
  background-color: #f6c43b;
  color: #fff;
}

.c3:hover {
  background-color: #ffe08b;
}

.c4 {
  background-color: #5dbeb1;
  color: #fff;
}

.c4:hover {
  background-color: #a0ece2;
}

.teacher-avatar {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  cursor: pointer;
  border: 2px solid transparent;
}

.current .teacher-avatar {
  border-color: #e6a13c;
}

.current .center {
  color: #e6a13c;
  font-weight: bold;
}
</style>
